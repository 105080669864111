import { useEffect, useState } from "react";
import googleOneTap from 'google-one-tap';
import StorageService from "../../data/storage/storage-service";
import ApiConfig from "../../data/api/api-config";

const GoogleOneTapLogin = () => {

    const apiConfig = new ApiConfig();
    const [baseUrl, setBaseUrl] = useState("");

    useEffect(() => {
        setBaseUrl(apiConfig.getBaseURL);
    }, []);

    useEffect(() => {
        console.log("apiConfig", baseUrl);
        if (!StorageService.isLoggedIn() && baseUrl) {
            const options = {
                client_id: '618065698598-5qvbvq6cupnnt47us14a231f7pbgbdcs.apps.googleusercontent.com',
                auto_select: false,
                cancel_on_tap_outside: false,
                context: 'signin',
            };
            googleOneTap(options, (response) => {
                console.log('response', response);
                window.location.href = baseUrl + '/oauth2/authorization/google';
            });
        }
    }, [baseUrl])

    return (
        <></>
    )
};

export default GoogleOneTapLogin;