import { Carousel, Col, Modal, Row } from "react-bootstrap";
import { Header } from "../../../layouts";
import "./professional-page-chat.css";
import { useEffect, useRef, useState } from "react";
import Avatar from "../../../assets/profile-pic.jpeg";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import StorageService from "../../../data/storage/storage-service";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCamera, faCircle, faClose, faDownload, faFileAlt, faImage, faPaperPlane, faSearch, faSmile, faVideo } from "@fortawesome/free-solid-svg-icons";
import EmojiPicker from 'emoji-picker-react';
import ApiConfig from "../../../data/api/api-config";
import VeeduCustomizedVideoTag from "../../../layouts/customized-tag/veedu-customized-video-tag/veedu-customized-video-tag";
import ProfessionalPageDocumentMessage from "./professional-page-document-message";
import ProfessionalPageMediaMessage from "./professional-page-media-message";
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import VeeduSpinner from "../../../layouts/customized-tag/veedu-spinner/veedu-spinner";
import { useLocation } from "react-router-dom";
import VeeduSideNav from "../../../layouts/veedu-sidenav/veedu-sidenav";
import LoginPageDialog from "../../login/login-page-dialog";
import { FaMicrophone, FaPaperPlane, FaStop, FaTrash } from "react-icons/fa";

const ProfessionalPageChat = () => {

  const location = useLocation();
  const { data } = location.state || {};
  const professionalPageApiService = new ProfessionalPageApiService();
  const [professionalPageUsersData, setProfessionalPageUsersData] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>(data || null);
  const [senderPageId, setSenderPageId] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const [messages, setMessages] = useState<any[]>([]);
  const [message, setMessage] = useState<string>('');
  const [client, setClient] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [selectedPreviewImage, setSelectedPreviewImage] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const apiConfig = new ApiConfig();
  const [baseUrl, setBaseUrl] = useState("");
  const messageEndRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownOpenRef = useRef(null);
  const [animationKey, setAnimationKey] = useState<number>(0);
  const playingVideoRef = useRef(null);
  const [showCamera, setShowCamera] = useState(false);
  const [cameraStream, setCameraStream] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState<Record<number, boolean>>({});
  const [selectedFilter, setSelectedFilter] = useState<string>("inbox");
  const dateRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const [veeduSpinner, setVeeduSpinner] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0); // State to keep track of time
  const [intervalId, setIntervalId] = useState(null); 
  const [showLogInDialog, setShowLogInDialog] = useState(false);
  const handleShowLogInDialog = () => setShowLogInDialog(true);
  const handleCloseLogInDialog = () => setShowLogInDialog(false);
  const toggleRecording = () => {
    if (isRecording) {
      // Stop recording
      clearInterval(intervalId);
      setIsRecording(false);
    } else {
      // Start recording
      setIsRecording(true);
      setTimer(0); // Reset timer when starting
      const id = setInterval(() => {
        setTimer((prevTime) => prevTime + 1);
      }, 1000);
      setIntervalId(id);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
  };


  const handleSlideChange = (selectedIdx) => {
    if (playingVideoRef.current) {
      playingVideoRef.current.pause();
      playingVideoRef.current = null;
    }
    setSelectedIndex(selectedIdx);
  };

  const handleVideoPlay = (videoRef) => {
    if (playingVideoRef.current && playingVideoRef.current !== videoRef) {
      playingVideoRef.current.pause();
    }
    playingVideoRef.current = videoRef;
  };

  const handlePreviewImageClick = (image) => {
    setSelectedPreviewImage(image);
  };

  useEffect(() => {
    if (imagePreviews.length > 0) {
      setSelectedPreviewImage(imagePreviews[imagePreviews.length - 1]);
    }
  }, [imagePreviews]);

  const handleToggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleEmojiToggle = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
      setShowEmojiPicker(false);
    }
    if (dropdownOpenRef.current && !dropdownOpenRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const scrollToBottomSmooth = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToBottomInstant = () => {
    const timer = setTimeout(() => {
      if (messageEndRef.current) {
        messageEndRef.current.scrollIntoView({ behavior: "instant" });
      }
    }, 500);
    return () => clearTimeout(timer);
  };

  const handleImageClick = (index, message) => {
    const filteredMedia = message.media.filter(media => media.mediaType !== "DOCUMENT");
    const filteredIndex = filteredMedia.findIndex(media => media === message.media[index]);
    setSelectedIndex(filteredIndex);
    setSelectedMessage(message);
  };

  const handleClose = () => {
    setSelectedIndex(null);
    setSelectedMessage(null);
  };

  const handleFileChange = (event: any) => {
    const files = event.target.files;
    if (files.length > 0) {
      let mediaFiles: any = [];
      for (let i = 0; i < files.length; i++) {
        let media: any = {};
        media.file = files[i];
        media.fileSize = files[i].size;
        if (media.file.type.startsWith('image/')) {
          media.mediaUrl = URL.createObjectURL(media.file);
          media.mediaType = 'IMAGE';
        } else if (media.file.type.startsWith('video/')) {
          media.mediaUrl = URL.createObjectURL(media.file);
          media.mediaType = 'VIDEO';
        } else if (media.file.type.startsWith('application/') || media.file.type.startsWith('text/')) {
          media.mediaUrl = URL.createObjectURL(media.file);
          media.mediaType = 'DOCUMENT';
        }
        mediaFiles.push(media);
      }
      setSelectedFiles(prevImages => [...prevImages, ...mediaFiles]);
      setImagePreviews(prevImages => [...prevImages, ...mediaFiles]);
    }
  }

  const handleRemoveImage = (index: number) => {
    setImagePreviews((prevImages) => prevImages.filter((_, i) => i !== index));
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const getProfessionalPageDetails = async () => {
    setVeeduSpinner(true);
    try {
      const userId = StorageService.getUserId();
      console.log(userId);
      let res = await professionalPageApiService.getProfessionalPageData(userId);
      const activePageItem = res.find(item => item.activePage === true);
      setSenderPageId(activePageItem?.id);
    } catch (error) {
      console.log(error);
    } finally {
      setVeeduSpinner(false);
    }
  };

  const getAllProfessionalPagData = async (userPageId) => {
    setVeeduSpinner(true);
    try {
      let url = `?page=${currentPage}&pageSize=${pageSize}&logInPageId=${userPageId}`;
      let res = await professionalPageApiService.getAllProfessionalPageData(url);
      setProfessionalPageUsersData(res.data);
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setVeeduSpinner(false);
    }
  };

  const sendMessage = async () => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      handleShowLogInDialog();
      return; 
    }
  
    if (isConnected && (message.trim() || selectedFiles.length > 0) && selectedUser) {
      setIsSending(true);
      const formData = new FormData();
      formData.append("senderId", senderPageId);
      formData.append("receiverId", selectedUser.id);
      formData.append("content", message.trim());
      let fileSizes = [];
      if (selectedFiles.length > 0) {
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append("chatMediaUrl", selectedFiles[i].file);
          fileSizes.push(selectedFiles[i].file.size);
        }
        formData.append("chatMediaSize", JSON.stringify(fileSizes));
      }
      try {
        let data = await professionalPageApiService.saveProfessionalPageChat(formData);
        const media = data.media?.map(item => ({
          mediaId: item.mediaId,
          mediaUrl: item.mediaUrl,
          mediaType: item.mediaType,
          mediaSize: item.mediaSize,
          mediaName: item.mediaName,
        })) || [];
        console.log('Chat saved:', data);
        const messageData = {
          id: data.id,
          senderId: senderPageId,
          receiverId: selectedUser.id,
          content: data.content,
          media: media,
          messageDateTime: data.messageDateTime,
          seen: data.seen,
        };
        client?.publish({
          destination: '/app/send',
          body: JSON.stringify(messageData),
        });
        setMessages((prevMessages) => {
          const updatedMessages = prevMessages.map((user) =>
            user.id === selectedUser.id
              ? { ...user, messages: [...user.messages, messageData] }
              : user
          );
          if (!updatedMessages.some(user => user.id === selectedUser.id)) {
            updatedMessages.push({
              id: selectedUser.id,
              name: selectedUser.name,
              profileImageUrl: selectedUser.profileImageUrl,
              professionTypeLabel: selectedUser.professionTypeLabel,
              messages: [messageData],
            });
          }
          updatedMessages.sort((a, b) => {
            const dateTimeA = a.messages[a.messages.length - 1]?.messageDateTime || "";
            const dateTimeB = b.messages[b.messages.length - 1]?.messageDateTime || "";
            return dateTimeB.localeCompare(dateTimeA);
          });
          console.log('updatedMessages', updatedMessages);
          setProfessionalPageUsersData(updatedMessages);
          return updatedMessages;
        });
        setDropdownOpen(false);
        setMessage('');
        setSelectedFiles([]);
        setImagePreviews([]);
      } catch (error) {
        console.error('Error saving chat:', error);
      } finally {
        setIsSending(false);
      }
    }
  };
  

  const handleUserSelect = (page) => {
    setMessage('');
    setSelectedFiles([]);
    setImagePreviews([]);
    setSelectedUser(page);
    setAnimationKey((prevKey) => prevKey + 1);
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
    e.target.style.height = "auto";
    requestAnimationFrame(() => {
      e.target.style.height = `${Math.min(e.target.scrollHeight, 72)}px`;
    });
  };
 

 
  const onEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => {
      const textarea = document.querySelector("textarea");
      if (!textarea) return prevMessage;
      const cursorPosition = textarea.selectionStart;
      const beforeCursor = prevMessage.slice(0, cursorPosition);
      const afterCursor = prevMessage.slice(cursorPosition);
      const updatedMessage = beforeCursor + emojiObject.emoji + afterCursor;
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = cursorPosition + emojiObject.emoji.length;
      }, 0);
      console.log('updated message', updatedMessage);
      return updatedMessage;
    });
  };

  useEffect(() => {
    setBaseUrl(apiConfig.getBaseURL());
  }, [apiConfig]);

  useEffect(() => {
    if (!baseUrl) return;
    const socketUrl = `${baseUrl}/ws`;
    console.log('socketUrl', socketUrl);
    const socket = new SockJS(socketUrl);
    const stompClient = new Client({
      webSocketFactory: () => socket,
      reconnectDelay: 3000,
      connectHeaders: {
        userId: StorageService.getUserId()?.toString() || "",
      },
      onConnect: () => {
        console.log("Connected to WebSocket");
        setIsConnected(true);
        getProfessionalPageDetails();
      },
      onDisconnect: () => {
        console.log("Disconnected from WebSocket");
        setIsConnected(false);
      },
      debug: (str) => console.log("[WebSocket Debug]", str),
    });
    stompClient.activate();
    setClient(stompClient);
    return () => {
      stompClient.deactivate();
    };
  }, [baseUrl]);

  useEffect(() => {
    if (selectedUser && messages.find(msg => msg.id === selectedUser?.id)?.messages) {
      messages.find(msg => msg.id === selectedUser?.id)?.messages.forEach(msg => {
        if (!msg.seen && msg.senderId !== senderPageId) {
          const messageData = {
            id: msg.id,
            senderId: msg.senderId,
            receiverId: msg.receiverId,
            content: msg.content,
            media: msg.media,
            messageDateTime: msg.messageDateTime,
            seen: true,
          };
          client?.publish({
            destination: '/app/seen',
            body: JSON.stringify(messageData),
          });
          setMessages((prevMessages) => {
            const updatedMessages = prevMessages.map((user) =>
              user.id === selectedUser.id
                ? {
                  ...user,
                  messages: user.messages.map((msg) =>
                    !msg.seen && msg.senderId !== senderPageId
                      ? { ...msg, seen: true }
                      : msg
                  ),
                }
                : user
            );
            console.log('updatedMessagesaaaaaaaaaaaaa', updatedMessages);
            return updatedMessages;
          });

        }
      });
    }
  }, [selectedUser, messages]);

  useEffect(() => {
    if (isConnected && client) {
      const subscription = client.subscribe(`/topic/messages/${senderPageId}`, (messageOutput) => {
        const messageData = JSON.parse(messageOutput.body);
        const chatKey = messageData.senderId === senderPageId
          ? messageData.receiverId
          : messageData.senderId;
        setMessages((prevMessages) => {
          const updatedMessages = prevMessages.map((user) =>
            user.id === chatKey
              ? {
                ...user,
                messages: user.messages.some(msg => msg.id === messageData.id)
                  ? user.messages.map(msg =>
                    msg.id === messageData.id ? { ...msg, seen: messageData.seen } : msg
                  )
                  : [...user.messages, messageData],
              }
              : user
          );
          if (!updatedMessages.some(user => user.id === chatKey)) {
            updatedMessages.push({
              id: chatKey,
              name: messageData.senderId === senderPageId ? selectedUser.name : messageData.senderName, // Ensure correct name
              profileImageUrl: messageData.profileImageUrl || '',
              professionTypeLabel: messageData.professionTypeLabel || '',
              messages: [messageData],
            });
          }
          updatedMessages.sort((a, b) => {
            const dateTimeA = a.messages[a.messages.length - 1]?.messageDateTime || "";
            const dateTimeB = b.messages[b.messages.length - 1]?.messageDateTime || "";
            return dateTimeB.localeCompare(dateTimeA);
          });
          console.log('updatedMessageshhhhhhhhhhhhhh', updatedMessages);
          setProfessionalPageUsersData(updatedMessages);
          return updatedMessages;
        });
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [isConnected, client, senderPageId, selectedUser]);

  useEffect(() => {
    // getProfessionalPageDetails();
  }, []);

  useEffect(() => {
    scrollToBottomSmooth();
  }, [messages]);

  useEffect(() => {
    scrollToBottomInstant();
  }, [selectedUser]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 992);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCameraClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraStream(stream);
      setShowCamera(true);
      setDropdownOpen(false);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    if (cameraStream) {
      const tracks = cameraStream.getTracks();
      tracks.forEach((track) => track.stop());
      setCameraStream(null);
      setShowCamera(false);
    }
  };

  const capturePhoto = () => {
    const videoElement = document.querySelector('video');
    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const media = {
        mediaUrl: url,
        mediaType: 'CAMERA',
        file: blob,
      };
      setSelectedFiles((prev) => [...prev, media]);
      setImagePreviews((prev) => [...prev, media]);
    });
    stopCamera();
  };

  useEffect(() => {
    return () => {
      stopCamera();
    };
  }, [cameraStream]);

  const formatFileSize = (bytes) => {
    if (bytes < 1024) return `${bytes} B`;
    if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(1)} KB`;
    if (bytes < 1024 * 1024 * 1024) return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
    return `${(bytes / (1024 * 1024 * 1024)).toFixed(1)} GB`;
  };

  useEffect(() => {
    if (isConnected && client) {
      const fetchUserStatus = async () => {
        try {
          let res = await professionalPageApiService.getUserStatus();
          setOnlineUsers(res);
        } catch (err) {
          console.error("Error fetching online users:", err);
        }
      };
      fetchUserStatus();
      const subscription = client.subscribe("/topic/user-status", (messageOutput) => {
        const onlineUsersData = JSON.parse(messageOutput.body);
        setOnlineUsers(onlineUsersData);
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [isConnected, client]);

  const fetchChatMessages = async (senderPageId) => {
    setVeeduSpinner(true);
    try {
      let url = `?senderId=${senderPageId}`;
      const res = await professionalPageApiService.getChatMessages(url);
      setMessages(res);
      return res;
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    } finally {
      setVeeduSpinner(false);
    }
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedFilter === "all") {
        getAllProfessionalPagData(senderPageId);
      } else if (selectedFilter === "inbox") {
        if (senderPageId) {
          const chatMessages = await fetchChatMessages(senderPageId);
          setProfessionalPageUsersData(chatMessages);
        }
      }
    }
    fetchData();
  }, [selectedFilter, senderPageId]);

  const formatMessageDate = (dateString: string): string => {
    const messageDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (
      messageDate.getDate() === today.getDate() &&
      messageDate.getMonth() === today.getMonth() &&
      messageDate.getFullYear() === today.getFullYear()
    ) {
      return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    } else if (
      messageDate.getDate() === yesterday.getDate() &&
      messageDate.getMonth() === yesterday.getMonth() &&
      messageDate.getFullYear() === yesterday.getFullYear()
    ) {
      return 'Yesterday';
    } else {
      return messageDate.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
    }
  };

  const groupMessagesByDate = (messages: any[]) => {
    return messages.reduce((acc, msg) => {
      const messageDate = new Date(msg.messageDateTime).toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit' });
      if (!acc[messageDate]) {
        acc[messageDate] = [];
      }
      acc[messageDate].push(msg);
      return acc;
    }, {} as Record<string, any[]>);
  };

  const groupedMessages = groupMessagesByDate(messages?.find(msg => msg.id === selectedUser?.id)?.messages || []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            break;
          }
        }
      },
      { root: document.querySelector(".professional_page_message-msg-body"), threshold: 0.5 }
    );
    Object.values(dateRefs.current).forEach((dateHeader) => {
      if (dateHeader) observer.observe(dateHeader);
    });
    return () => observer.disconnect();
  }, [groupedMessages]);

  const handleDownload = async (fileUrl, filename) => {
    try {
      let response = await fetch(fileUrl);
      if (response && response.blob) {
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } else {
        console.error("Invalid response or blob function missing.");
      }
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  return <>
    <div id="veedu-app-container" style={{ background: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)' }}>
      <VeeduSideNav></VeeduSideNav>
      <div className="container-fluid pb-0 h-100"
        style={{ overflow: 'hidden' }}>
        {veeduSpinner && <VeeduSpinner />}
        <Row className="h-100">
          {/* User List */}
          <Col lg={4} className={`h-100 ${isMobileView && selectedUser ? 'd-none' : ''}`}
            style={{ borderRight: '1px solid #ccc' }}>
            <div className="gap-2 h-100"
              style={{ display: 'grid', gridTemplateRows: 'auto auto auto 1fr' }}>
              <Col lg={12} className="text-center">
                <h2 className="my-2">Chats</h2>
              </Col>
              <Col lg={12} className="">
                <div className="professional_page_chat-search-box">
                  <input className="professional_page_chat-search-input w-100 p-3 pe-5" type="text" name="" placeholder="Search something" />
                  <button className="professional_page_chat-search-button p-2">
                    <FontAwesomeIcon className="fs-5"
                      icon={faSearch}
                    />
                  </button>
                </div>
              </Col>
              <Col lg={12}>
                <div className="professional_page_chat-radio-inputs">
                  <label className="professional_page_chat-radio">
                    <input name="radio" type="radio" value="inbox"
                      checked={selectedFilter === "inbox"}
                      onChange={handleFilterChange} />
                    <span className="professional_page_chat-name fs-5">Inbox</span>
                  </label>
                  <label className="professional_page_chat-radio">
                    <input name="radio" type="radio" value="all"
                      checked={selectedFilter === "all"}
                      onChange={handleFilterChange} />
                    <span className="professional_page_chat-name fs-5">All</span>
                  </label>
                </div>
              </Col>
              <Col lg={12} className="py-2 switch-account-scrollbar h-100 container-fluid" style={{ height: 'calc(100vh - 276px)', overflowY: 'auto', overflowX: 'hidden' }}>
                <Row className="g-3">
                  {Array.isArray(professionalPageUsersData) && professionalPageUsersData.map((page, pageIndex) => (
                    <Col lg={12} key={pageIndex}
                      className="d-flex align-items-center"
                      onClick={() => handleUserSelect(page)}
                      style={{ cursor: 'pointer', gap: '5px', borderRadius: '1rem', backgroundColor: selectedUser?.id === page.id ? '#f0f0f0' : '' }}>
                      <div className="position-relative">
                        <img
                          className="page_message_profile_img"
                          src={page.profileImageUrl || Avatar}
                          alt="Profile"
                          height={60}
                          style={{ border: onlineUsers[page.id] ? "3px solid #66cc33" : "none", }}
                        />
                        {onlineUsers[page.id] &&
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{ position: "absolute", top: 0, right: "2px", color: "#66cc33" }}
                          />
                        }
                      </div>
                      <Row className="d-flex justify-content-between w-100 p-2">
                        <Col lg={12} className="d-flex justify-content-between align-items-center">
                          <span className="fw-bold" style={{ wordBreak: 'break-all', fontSize: 'large' }}>
                            {page.name}
                          </span>
                          {messages.find(msg => msg.id === page.id)?.messages && messages.find(msg => msg.id === page.id)?.messages.length > 0 && (
                            <span className="text-muted" style={{ fontSize: 'small', flex: 'none' }}>
                              {formatMessageDate(messages.find(msg => msg.id === page.id)?.messages[messages.find(msg => msg.id === page.id)?.messages.length - 1]?.messageDateTime)}
                            </span>
                          )}
                        </Col>
                        <Col lg={12} className="d-flex justify-content-between align-items-center">
                          {(() => {
                            const pageMessages = messages.find(msg => msg.id === page.id)?.messages || [];
                            const lastMessage = pageMessages.at(-1);
                            const unreadCount = pageMessages.filter(msg => msg.senderId === page.id && !msg.seen).length;

                            return (
                              <>
                                <span className="text-muted d-flex fw-bold"
                                  style={{ gap: "5px", wordBreak: 'break-all', overflow: 'hidden' }}>

                                  {lastMessage?.senderId === senderPageId && (
                                    <DoneAllRoundedIcon className="me-1" style={{ color: lastMessage.seen ? "#15b017" : "grey" }} />
                                  )}

                                  {lastMessage?.content ? (
                                    <span style={{ display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: 1, overflow: "hidden" }}>{lastMessage.content}</span>
                                  ) : lastMessage?.media?.length ? ((() => {
                                    const lastMedia = lastMessage?.media?.at(-1);
                                    switch (lastMedia?.mediaType) {
                                      case "IMAGE": return <span><FontAwesomeIcon icon={faImage} className="me-1" />Image</span>;
                                      case "VIDEO": return <span><FontAwesomeIcon icon={faVideo} className="me-1" />Video</span>;
                                      case "DOCUMENT": return (
                                        <span style={{ display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: 1, overflow: "hidden" }}>
                                          <FontAwesomeIcon icon={faFileAlt} className="me-1" />
                                          {lastMedia?.mediaName || "Document"}
                                        </span>
                                      );
                                      default: return "No messages";
                                    }
                                  })()
                                  ) : (
                                    <span>No messages</span>
                                  )}
                                </span>

                                {unreadCount > 0 && (
                                  <span className="badge bg-danger" style={{ fontSize: '0.75rem', padding: '0.25em 0.5em', borderRadius: '10px' }}>
                                    {unreadCount}
                                  </span>
                                )}
                              </>
                            );
                          })()}
                        </Col>

                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
            </div>
          </Col>
          {/* Chat */}
          {selectedUser && (
            <Col key={animationKey} lg={8}
              className={`d-flex professional_page_message-chat-container p-0 h-100 ${isMobileView && !selectedUser ? 'd-none showChatContainer' : ''}`}>
              <div className="w-100 gap-2"
                style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto' }}>
                <Col lg={12} className="p-3 d-flex align-items-center"
                  style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", gap: '10px', wordBreak: 'break-all' }}>
                  <ArrowBackIosIcon onClick={() => setSelectedUser(null)}
                    style={{ cursor: 'pointer' }} />
                  <div className="position-relative">
                    <img
                      className="page_chat_profile_img"
                      src={selectedUser?.profileImageUrl || Avatar}
                      alt="Profile"
                      height={60}
                      style={{ border: onlineUsers[selectedUser?.id] ? "3px solid #66cc33" : "none", }}
                    />
                    {onlineUsers[selectedUser?.id] &&
                      <FontAwesomeIcon
                        icon={faCircle}
                        style={{ position: "absolute", top: 0, right: "2px", color: "#66cc33" }}
                      />
                    }
                  </div>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <span className="fw-bold" style={{ wordBreak: 'break-all', fontSize: 'large' }}>{selectedUser?.name}</span>
                    <span className="fw-bold text-muted" style={{ wordBreak: 'break-all' }}>{selectedUser?.professionTypeLabel}</span>
                  </div>
                </Col>
                {imagePreviews.length < 1 && !showCamera ? (
                  <>
                    <div className="switch-account-scrollbar" style={{ overflowY: "auto", overflowX: "hidden" }}>
                      <ul id="professional_page_message-msg-body" className="professional_page_message-msg-body m-0 p-3" style={{ wordBreak: 'break-all' }}>
                        {Object.keys(groupedMessages).map((date, index) => (
                          <div key={index} ref={(el) => (dateRefs.current[date] = el)}>
                            {/* Date Header */}
                            <div
                              className="text-center text-muted my-2"
                              style={{
                                position: 'sticky',
                                top: '10px',
                                zIndex: 1,
                              }}
                            >
                              <span className="fw-bold p-2" style={{
                                fontSize: 'small',
                                background: '#fff',
                                borderRadius: '10px',
                              }}>
                                {date}</span>
                            </div>
                            {/* Messages for this date */}
                            {groupedMessages[date].map((msg, idx) => (
                              <>
                                {msg.media.map((media, index) => (
                                  < li key={`media-${msg.id}-${index}`} className={`my-2 ${msg.senderId === senderPageId ? "sender" : "receiver"}`}>
                                    <div
                                      key={index}
                                      className="professional_page_message-msg-body-list p-2"
                                      style={{ maxWidth: window.innerWidth > 425 ? "50%" : "100%" }}
                                    >
                                      {media.mediaType === "DOCUMENT" ? (
                                        <ProfessionalPageDocumentMessage media={media} formatFileSize={formatFileSize} />
                                      ) : media.mediaType === "IMAGE" || media.mediaType === "VIDEO" ? (
                                        <ProfessionalPageMediaMessage media={media} handleImageClick={() => handleImageClick(index, msg)} />
                                      ) : null}
                                    </div>
                                    <span className="time mx-1 align-items-center"
                                      style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: '5px' }}>
                                      <span>
                                        {new Date(msg.messageDateTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                                      </span>
                                      {msg.senderId === senderPageId &&
                                        <DoneAllRoundedIcon
                                          style={{ color: msg.seen ? "#15b017" : "grey" }}
                                        />
                                      }
                                    </span>
                                  </li>
                                ))}
                                {msg.content && (
                                  <li key={`content-${msg.id}`} className={`my-2 ${msg.senderId === senderPageId ? "sender" : "receiver"}`}>
                                    <div className="professional_page_message-msg-body-list p-2" style={{ maxWidth: window.innerWidth > 425 ? "50%" : "100%" }}>
                                      <p className="text-start m-0">{msg.content}</p>
                                    </div>
                                    <span className="time mx-1 align-items-center"
                                      style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: '5px' }}>
                                      <span>
                                        {new Date(msg.messageDateTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                                      </span>
                                      {msg.senderId === senderPageId &&
                                        <DoneAllRoundedIcon
                                          style={{ color: msg.seen ? "#15b017" : "grey" }}
                                        />
                                      }
                                    </span>
                                  </li>
                                )}
                              </>
                            ))}
                          </div>
                        ))}
                        <div ref={messageEndRef}></div>
                      </ul>
                    </div>
                    
                    <div className="p-3 d-flex align-items-center gap-2 align-self-end"
                      style={{ boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)", borderTop: "1px solid #ddd" }}>
                      <div className="position-relative professional_page_message-media" ref={dropdownOpenRef}>
                      {!isRecording && (
                        <button
                          type="button"
                          className="btn btn-light rounded-circle p-2 d-flex align-items-center justify-content-center"
                          style={{ cursor: "pointer" }}
                          onClick={handleToggle}
                        >
                          <FontAwesomeIcon
                            icon={faAdd}
                            style={{
                              fontSize: "1.5rem",
                              color: "#666",
                              transition: "transform 0.3s ease-in-out",
                              transform: dropdownOpen ? "rotate(135deg)" : "rotate(0deg)",
                            }}
                          />
                        </button>
                       )}
                        <ul className={`p-2 ${dropdownOpen ? "open" : ""}`}>
                          <li className="p-2">
                            <label className="d-flex align-items-center gap-3">
                              <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: "1.5rem", color: "#666" }} aria-hidden={true} />
                              <span style={{ color: "#333", fontSize: "1.25rem" }}>Document</span>
                              <input
                                id="document-upload"
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                multiple
                                accept=".pdf, .doc, .docx, .txt"
                              />
                            </label>
                          </li>
                          <li className="p-2">
                            <label className="d-flex align-items-center gap-3">
                              <FontAwesomeIcon icon={faImage} style={{ fontSize: "1.5rem", color: "#666" }} aria-hidden={true} />
                              <span style={{ color: "#333", fontSize: "1.25rem" }}>Photos & Videos</span>
                              <input id="image-upload" type="file" style={{ display: "none" }}
                                onChange={handleFileChange}
                                multiple
                                accept="image/*,video/*" />
                            </label>
                          </li>
                          <li className="p-2">
                            <label className="d-flex align-items-center gap-3" onClick={handleCameraClick}>
                              <FontAwesomeIcon icon={faCamera} style={{ fontSize: "1.5rem", color: "#666" }} aria-hidden={true} />
                              <span style={{ color: "#333", fontSize: "1.25rem" }}>Camera</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      {!isRecording && (
                      <div className="w-100 textarea-container position-relative d-flex align-items-center"
                        ref={emojiPickerRef}
                        style={{ borderRadius: '10px', backgroundColor: 'aliceblue', padding: '0.5rem' }}>
                        <button
                          type="button"
                          className="btn btn-light rounded-circle p-2 d-flex align-items-center justify-content-center"
                          onClick={handleEmojiToggle}
                          style={{
                            fontSize: '1.5rem',
                            color: '#666',
                            marginRight: '0.5rem',
                          }}
                        >
                          <FontAwesomeIcon icon={faSmile} aria-hidden="true" />
                        </button>
                        <div className={`emoji-picker-container p-2 ${showEmojiPicker ? "showEmojiPicker" : ""}`}>
                          <EmojiPicker onEmojiClick={onEmojiClick} />
                        </div>
                        <textarea
                          className="switch-account-scrollbar w-100"
                          placeholder="Write a message…"
                          value={message}
                          onChange={(e) => handleInputChange(e)}
                          rows={1}
                          onFocus={() => setShowEmojiPicker(false)}
                          style={{
                            overflowY: "auto", maxHeight: "4.5em", resize: "none", border: 'none', backgroundColor: 'transparent',
                            outline: 'none',
                          }}
                        />
                      
                      </div>
                      )}
                      <button
      type="button"
      style={{
        cursor: 'pointer',
        padding: '7px',
        borderRadius: '50%',
        border: 'none',
        background: 'none',
        transition: 'transform 0.2s ease',
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={toggleRecording}
    >
      {isRecording ? (
        <>
          <FaTrash
            style={{
              color: 'grey',
              fontSize: '20px',
              cursor: 'pointer',
            }}
          />
          <span style={{ margin: '0 8px', color: 'black' }}>
            {formatTime(timer)}
          </span>
          <FaStop style={{ color: 'red', fontSize: '20px' }} />
        </>
      ) : (
        <FaMicrophone style={{ color: 'grey', fontSize: '20px' }} />
      )}
    </button>
    {isRecording && (
                        <button type="button" className="professional_page_chat-send-message-button p-3 d-flex align-items-center justify-content-center"
                          onClick={sendMessage}
                          
                          style={{ opacity: isSending ? 0.5 : 1, cursor: isSending ? "not-allowed" : "pointer" }}>
                          <FontAwesomeIcon icon={faPaperPlane} aria-hidden="true" style={{ fontSize: "1.5rem" }} />
                        </button>
    )}
                      





                      {message.trim().length > 0 && (
                        <button type="button" className="professional_page_chat-send-message-button p-3 d-flex align-items-center justify-content-center"
                          onClick={sendMessage}
                          disabled={isSending || message.trim().length === 0}
                          style={{ opacity: isSending ? 0.5 : 1, cursor: isSending ? "not-allowed" : "pointer" }}>
                          <FontAwesomeIcon icon={faPaperPlane} aria-hidden="true" style={{ fontSize: "1.5rem" }} />
                        </button>
                      )}
                    </div>
                  </>
                ) : showCamera ? (
                  <>
                  
                    <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                      <div className="p-3 d-flex align-items-center"
                        style={{ gap: '20px' }}>
                        <FontAwesomeIcon icon={faClose}
                          onClick={stopCamera}
                          style={{ cursor: 'pointer', fontSize: 'x-large', fontWeight: 'bold' }} />
                        <span className="" style={{ fontSize: 'x-large', fontWeight: 'bold' }}>Take Photo</span>
                      </div>
                      <div className="d-flex justify-content-center position-relative" style={{ maxHeight: "calc(100vh - 260px)" }}>
                        <video
                          ref={(video) => {
                            if (video && cameraStream) {
                              video.srcObject = cameraStream;
                            }
                          }}
                          autoPlay
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '10px',
                          }}
                        />
                        <span className="position-absolute"
                          style={{ bottom: '-30px' }}
                          onClick={capturePhoto}>
                          <FontAwesomeIcon icon={faCamera} className="p-3"
                            style={{
                              color: "white", fontSize: '2rem', borderRadius: '50%', backgroundColor: "#0093E9",
                              background: "linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)"
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {/* <div className="gap-2"
                      style={{ display: 'grid', gridTemplateRows: '1fr auto', maxHeight: "calc(100vh - 280px)" }}> */}
                    <div className="d-flex justify-content-center align-items-center" style={{ overflow: 'hidden' }}>
                      {selectedPreviewImage.mediaType === "IMAGE" || selectedPreviewImage.mediaType === "CAMERA" ? (
                        <img
                          className="h-100 w-100"
                          src={selectedPreviewImage.mediaUrl}
                          alt={`Preview`}
                          style={{ borderRadius: '10px', objectFit: 'scale-down' }}
                        />
                      ) : selectedPreviewImage.mediaType === "VIDEO" ? (
                        <video
                          className="h-100 w-100"
                          src={selectedPreviewImage.mediaUrl}
                          controls
                          controlsList="nofullscreen nodownload"
                          style={{ borderRadius: '10px', objectFit: 'scale-down' }}
                        />
                      ) : selectedPreviewImage.mediaType === 'DOCUMENT' ? (
                        <div
                          className="document-preview d-flex"
                          style={{ maxWidth: '80%', overflow: 'hidden', flexFlow: 'column' }}>
                          <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: '5rem', color: '#666' }} />
                          <div className="d-flex" style={{ flexDirection: 'column' }}>
                            <strong className="" style={{ fontSize: '1rem', color: '#333', wordBreak: 'break-word' }}>{selectedPreviewImage.file.name}</strong>
                            <small className="text-muted">{formatFileSize(selectedPreviewImage.file.size)}</small>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex align-items-center gap-3 px-5">
                      <div className="w-100 textarea-container position-relative d-flex align-items-center"
                        ref={emojiPickerRef}
                        style={{ borderRadius: '10px', backgroundColor: 'aliceblue', padding: '0.5rem' }}>
                        <button
                          type="button"
                          className="btn btn-light rounded-circle p-2 d-flex align-items-center justify-content-center"
                          onClick={handleEmojiToggle}
                          style={{
                            fontSize: '1.5rem',
                            color: '#666',
                            marginRight: '0.5rem',
                          }}
                        >
                          <FontAwesomeIcon icon={faSmile} aria-hidden="true" />
                        </button>
                        <div className={`emoji-picker-container p-2 ${showEmojiPicker ? "showEmojiPicker" : ""}`}>
                          <EmojiPicker onEmojiClick={onEmojiClick} />
                        </div>
                        <textarea
                          className="switch-account-scrollbar w-100"
                          placeholder="Write a message…"
                          value={message}
                          onChange={(e) => handleInputChange(e)}
                          rows={1}
                          onFocus={() => setShowEmojiPicker(false)}
                          style={{
                            overflowY: "auto", maxHeight: "4.5em", resize: "none", border: 'none', backgroundColor: 'transparent',
                            outline: 'none',
                          }}
                        />
                      </div>
                   
                      <button type="button"
                        className="professional_page_chat-send-message-button p-3 d-flex align-items-center justify-content-center"
                        style={{ opacity: isSending ? 0.5 : 1, cursor: isSending ? "not-allowed" : "pointer" }}
                        onClick={sendMessage}>
                        <FontAwesomeIcon icon={faPaperPlane} aria-hidden="true" style={{ fontSize: "1.8rem" }} />
                      </button>
                      
                   
                    </div>
             
                    {selectedPreviewImage.mediaType != "CAMERA" && (
                      <Col lg={12}
                        className="p-3 align-items-center"
                        style={{
                          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)", borderTop: "1px solid #ddd", display: 'grid',
                          gridTemplateColumns: '1fr auto',
                          gap: '1rem',
                        }}>
                        <div
                          className="d-flex align-items-center gap-2 switch-account-horizontal-scrollbar"
                          style={{ overflowX: "auto", overflowY: "hidden" }}
                        >
                          {imagePreviews.map((preview, index) => (
                            <div
                              className="professional_page_message-media_preview"
                              key={index}
                              style={{
                                // height: '55px', width: '55px',
                                position: "relative",
                                display: "inline-block",
                              }}
                            >
                              {preview.mediaType === "IMAGE" ? (
                                <img
                                  className=""
                                  src={preview.mediaUrl}
                                  alt={`Preview ${index}`}
                                  height={55}
                                  width={55}
                                  style={{
                                    // borderRadius: "5px",
                                    objectFit: "cover",
                                    aspectRatio: "1/1",
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                    border: selectedPreviewImage === preview ? "4px solid #259ada" : "none",
                                  }}
                                  onClick={() => handlePreviewImageClick(preview)}
                                />
                              ) : preview.mediaType === "VIDEO" ? (
                                <video
                                  className=""
                                  src={preview.mediaUrl}
                                  style={{
                                    height: "55px",
                                    width: "55px",
                                    objectFit: "cover",
                                    aspectRatio: "1/1",
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                    border: selectedPreviewImage === preview ? "4px solid #259ada" : "none",
                                  }}
                                  onClick={() => handlePreviewImageClick(preview)}
                                />
                              ) : preview.mediaType === 'DOCUMENT' ? (
                                <div
                                  className="document-preview w-100 h-100 d-flex justify-content-center align-items-center"
                                  style={{
                                    cursor: "pointer", borderRadius: "10px",
                                    border: selectedPreviewImage === preview ? "4px solid #259ada" : "none",
                                  }}
                                  onClick={() => handlePreviewImageClick(preview)}>
                                  <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: '3rem', color: '#666' }} />
                                </div>
                              ) : null}
                              <span onClick={() => handleRemoveImage(index)}>
                                ×
                              </span>
                            </div>
                          ))}
                        </div>
                        <div>
                          <label className="d-flex align-items-center p-2"
                            style={{ border: '3px solid #666', borderRadius: '10px' }}>
                            <FontAwesomeIcon icon={faAdd} style={{ fontSize: "1.5rem", color: "#666" }} aria-hidden={true} />
                            <input id="image-upload" type="file" style={{ display: "none" }}
                              onChange={handleFileChange}
                              multiple
                              accept="image/,video/" />
                          </label>
                        </div>
                      </Col>
                    )}
                  </>
                )}
              </div>
            </Col>
          )}
        </Row>
        {selectedIndex !== null && selectedMessage && (
          <div className="d-flex justify-content-center align-items-center"
            style={{
              position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.9)',
              zIndex: 1000
            }}>
            <Carousel
              className="w-100 h-100"
              activeIndex={selectedIndex}
              onSelect={handleSlideChange}
              indicators={selectedMessage.media.filter(media => media.mediaType !== "DOCUMENT").length > 1}
              controls={selectedMessage.media.filter(media => media.mediaType !== "DOCUMENT").length > 1}
              interval={null}
            >
              {selectedMessage.media
                .filter(media => media.mediaType !== "DOCUMENT")
                .map((media, idx) => (
                  <Carousel.Item key={idx} style={{ width: '100vw', height: '100vh' }}>
                    {media.mediaType === "IMAGE" ? (
                      <img
                        src={media.mediaUrl}
                        alt={`Slide ${idx + 1}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    ) : media.mediaType === "VIDEO" ? (
                      <VeeduCustomizedVideoTag mediaUrl={media.mediaUrl} onPause={() => handleVideoPlay(null)}
                        onPlay={handleVideoPlay} />
                    ) : null}
                    <div className="d-flex" style={{ position: 'absolute', top: '10px', right: '10px', gap: '10px', zIndex: '1001' }}>
                      {/* <span
                        style={{ color: "#fff", fontSize: "24px", cursor: "pointer" }}
                        title="Download"
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          aria-hidden="true"
                          style={{ fontSize: "1.5rem", color: "#fff" }}
                          onClick={() => handleDownload(media.mediaUrl, media.mediaUrl.split("/").pop())}
                        />
                      </span> */}
                      <span
                        onClick={handleClose}
                        style={{ color: '#fff', fontSize: '24px', cursor: 'pointer' }}
                        title="Close">
                        <FontAwesomeIcon icon={faClose} aria-hidden="true" style={{ fontSize: "1.5rem", color: "#fff" }} />
                      </span>
                    </div>
                  </Carousel.Item>
                ))}
            </Carousel>
          </div>
        )}
      </div >
    </div>
    <Modal show={showLogInDialog} onHide={handleCloseLogInDialog} backdrop="static"
            centered className="login-page-dialog-modal">
            <LoginPageDialog handleClose={handleCloseLogInDialog} />
          </Modal>
  </>
}
export default ProfessionalPageChat;